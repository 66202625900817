import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import {
  checkSelectedBusinessHasWhoAsk,
  getGlobalStats,
  getSelectedBusinessId
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Counter from "../Counter";
import DataConstants from "const/DataConstants";
import React from "react";
import UiRoutes from "const/UiRoutes";

const { TRANSACTIONS } = UiRoutes;

const { STATUSES: { NEED_REACTION } } = DataConstants;

const TransactionsAwaitingClientResponseCounter = () => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const globalStats = useSelector(getGlobalStats);

  const businessId = useSelector(getSelectedBusinessId);

  const selectedBusinessHasWhoAsk = useSelector(checkSelectedBusinessHasWhoAsk);

  const {
    transactions: {
      needReaction: { count = 0 } = {}
    } = {}
  } = globalStats;

  return (
    <Counter
      to={`/${businessId}${TRANSACTIONS}?status=${NEED_REACTION}`}
      theme="warning"
      mainText={count}
      secondaryText={uiTexts.transactions.toLowerCase()}
      description={selectedBusinessHasWhoAsk
        ? messages.dashboardTxsNeedReaction
        : messages.dashboardTxsNeedReactionAccountant}
      icon={Icons.WarningCircle}
      loading={!globalStats.transactions}
      className={Css.transactionsAwaitingClientResponseCounter} />
  );
};

export default React.memo(TransactionsAwaitingClientResponseCounter);
