import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkActivityFetching, getActivityData } from "selectors/activity";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import ActivityActions from "actions/ActivityActions";
import ActivityItem from "nlib/pages/ActivityPage/lib/ActivityItem";
import NoDataContent from "nlib/common/NoDataContent";
import Preloader from "nlib/ui/Preloader";
import React, { useEffect } from "react";

const LIMIT = 5;

const LastActivity = () => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const activityFetching = useSelector(checkActivityFetching);

  const activityData = useSelector(getActivityData);

  useEffect(() => {
    dispatch(ActivityActions.fetchActivityList({ limit: LIMIT }));
  }, [dispatch]);

  return (
    <div className={Css.lastActivity}>
      {activityData.length
        ? activityData.map((item) => <ActivityItem compact key={item.id} item={item} />)
        : (activityFetching ? <Preloader /> : (
          <NoDataContent
            icon={Icons.Lightning}
            title={uiTexts.noActivity} />
        )
        )}
    </div>
  );
};

export default React.memo(LastActivity);
