import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import fallbackLogoSrc from "assets/business.svg";

import * as Icons from "@phosphor-icons/react";
import { checkIntegrationsFetching } from "selectors/integrations";
import { checkUsersFetching, getProjectName } from "selectors/organizations";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import BusinessStatus from "nlib/common/BusinessStatus";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import IntegrationServices from "const/IntegrationServices";
import IntegrationsActions from "actions/IntegrationsActions";
import React, { useCallback } from "react";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import moment from "moment";
import useSyncIntegration from "hooks/useSyncIntegration";

const Banner = () => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const {
    name: businessName,
    createdAt: businessCreatedAt,
    extraData: {
      integrationService,
      integrationServiceConnected,
      transactionsLastSyncedAt,
      xeroBusinessHasTooMuchData
    } = {},
    extraData = {},
    settings = {}
  } = useSelector(getSelectedBusinessData);

  const usersFetching = useSelector(checkUsersFetching);

  const fetchingIntegrations = useSelector(checkIntegrationsFetching);

  const fetchingData = usersFetching;

  const projectName = useSelector(getProjectName);

  const syncIntegrations = useSyncIntegration();

  const {
    logo: serviceLogo,
    label: serviceName
  } = IntegrationServices.getByValue(integrationService)
    || { logo: fallbackLogoSrc, label: projectName };

  const lastComplete = transactionsLastSyncedAt ? Utils.replaceTextVars(
    uiTexts.lastSyncDate,
    { date: moment.duration(moment.utc(transactionsLastSyncedAt).diff(moment.utc())).humanize(true) }
  ) : null;

  const firstFullSync = integrationServiceConnected && !transactionsLastSyncedAt
    && moment.utc(businessCreatedAt).isAfter(moment.utc().subtract(1, "days"));

  const tooMuchData = !!xeroBusinessHasTooMuchData;

  const quickBooksDesktopBusiness = integrationService === IntegrationServices.QUICK_BOOKS_DESKTOP.value;

  const handleReconnectClick = useCallback(async(event) => {
    event.preventDefault();

    if (quickBooksDesktopBusiness) {
      window.location.reload();
    } else {
      dispatch(UiActions.togglePreloader(true));

      const consentUrl = await dispatch(IntegrationsActions.buildConsentUrl(integrationService));

      if (consentUrl) window.location.href = consentUrl;
      else dispatch(UiActions.togglePreloader(false));
    }
  }, [dispatch, integrationService, quickBooksDesktopBusiness]);

  const handleSyncIntegrationButtonClick = useCallback(() => {
    syncIntegrations();
  }, [syncIntegrations]);

  return (
    <div className={`${Css.banner} ${CommonCss.card}`}>
      <div className={Css.left}>
        <img title={serviceName} alt={serviceName} src={serviceLogo} />
        <div>
          <div className={Css.name}>{businessName}</div>
          <BusinessStatus
            extraData={extraData}
            settings={settings}
            onReconnectClick={handleReconnectClick} />
        </div>
      </div>
      <div className={Css.center}>{lastComplete}</div>
      <div className={Css.right}>
        {(integrationService && integrationService !== IntegrationServices.QUICK_BOOKS_DESKTOP.value) && (
          <>
            {firstFullSync
              ? (
                <span className={Css.syncInfo}>
                  <span>
                    <b>
                      {Utils.replaceTextVars(
                        uiTexts.syncingTransactionsFromDate,
                        {
                          date: moment()
                            .subtract(1, "years")
                            .startOf(tooMuchData ? "day" : "year")
                            .format(Constants.DATETIME_FORMATS.DATE_TEXT)
                        }
                      )}
                    </b>
                  </span>
                  <Icons.Spinner data-wait className={CommonCss.highlightText} />
                </span>
              )
              : (
                <Button
                  large outline
                  disabled={fetchingData || fetchingIntegrations || !integrationServiceConnected}
                  onClick={handleSyncIntegrationButtonClick}>
                  <span>
                    {fetchingIntegrations || !transactionsLastSyncedAt ? <Icons.Spinner data-wait /> : <Icons.ArrowsClockwise />}
                    {!firstFullSync && <span>{`${uiTexts.syncWith} ${Utils.capitalizeText(integrationService)}`}</span>}
                  </span>
                </Button>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(Banner);
