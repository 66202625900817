import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { NavLink, Redirect } from "react-router-dom";
import { checkIsBusinessUser } from "selectors/user";
import {
  checkSelectedBusinessHasBusinessUsers,
  checkSelectedBusinessRpaMode,
  getCurrentQuickBooksRealmId,
  getCurrentXeroOrganizationId,
  getGlobalStats,
  getSelectedBusinessData
} from "selectors/businesses";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Banner from "./lib/Banner";
import Countries from "const/Countries";
import DocumentsAwaitingReviewCounter from "./lib/DocumentsAwaitingReviewCounter";
import LastActivity from "./lib/LastActivity";
import MobileHeader from "mlib/common/MobileHeader";
import MonthEndCloseCounter from "./lib/MonthEndCloseCounter";
import Page from "nlib/common/Page";
import PageContent from "nlib/common/PageContent";
import PageHeader from "nlib/common/PageHeader";
import React from "react";
import TransactionsAwaitingClassificationCounter from "./lib/TransactionsAwaitingClassificationCounter";
import TransactionsAwaitingClientResponseCounter from "./lib/TransactionsAwaitingClientResponseCounter";
import TransactionsAwaitingReviewCounter from "./lib/TransactionsAwaitingReviewCounter";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";

const DashboardPage = ({ className }) => {
  const { uiTexts } = useSelector(getTextsData);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const { countryCode } = useSelector(getActiveOrganization);

  const businessUser = useSelector(checkIsBusinessUser);

  const {
    transactions: {
      needReaction: { count: needReactionCount } = {}
    } = {}
  } = useSelector(getGlobalStats);

  const czCountry = countryCode === Countries.CZ;

  const {
    id: businessId,
    settings: { advancedDocumentsWorkflow } = {}
  } = useSelector(getSelectedBusinessData);

  const selectedBusinessHasBusinessUsers = useSelector(checkSelectedBusinessHasBusinessUsers);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  if (businessUser) return <Redirect to={`/${businessId}${czCountry ? UiRoutes.DOCUMENTS : UiRoutes.TRANSACTIONS}`} />;

  return (
    <>
      {Utils.checkIsTouchDevice() && <MobileHeader />}
      <Page className={classNames(Css.dashboardPage, className)}>
        <PageContent>
          {!Utils.checkIsTouchDevice() && (
            <>
              <PageHeader />
              <Banner />
            </>
          )}
          <div className={Css.counters}>
            {!czCountry && (
              <>
                <TransactionsAwaitingClassificationCounter />
                <TransactionsAwaitingReviewCounter />
                {(selectedBusinessHasBusinessUsers || !!needReactionCount || rpaMode)
                  && <TransactionsAwaitingClientResponseCounter />}
                {!Utils.checkIsTouchDevice() && (quickBooksBusiness || xeroBusiness) && <MonthEndCloseCounter />}
              </>
            )}
            {advancedDocumentsWorkflow && <DocumentsAwaitingReviewCounter />}
          </div>
          <div className={Css.cards}>
            <div className={classNames(CommonCss.card, Css.card)}>
              <div className={Css.header}>
                <div className={Css.title}>
                  <Icons.Lightning />
                  <span>{uiTexts.recentActivity}</span>
                </div>
                <NavLink to={`/${businessId}${UiRoutes.ACTIVITY}`}>
                  <Button outline>{uiTexts.showAll}</Button>
                </NavLink>
              </div>
              <LastActivity />
            </div>
          </div>
        </PageContent>
      </Page>
    </>
  );
};

export default React.memo(DashboardPage);
