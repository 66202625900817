import { getActiveOrganization } from "selectors/organizations";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import BusinessesActions from "actions/BusinessesActions";
import Constants from "const/Constants";
import Countries from "const/Countries";
import IntegrationServices from "const/IntegrationServices";
import IntegrationsActions from "actions/IntegrationsActions";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import moment from "moment";

const useSyncIntegration = () => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const {
    id: businessId,
    extraData: { integrationService, transactionsLastSyncedAt } = {}
  } = useSelector(getSelectedBusinessData);

  return useCallback(async() => {
    const [
      integrationSyncConfirmMessageA,
      integrationSyncConfirmMessageB
    ] = messages.integrationSyncConfirm;

    const serviceName = Utils.capitalizeText(integrationService);

    const businessIds = [businessId];

    const message = [
      `${Utils.replaceTextVars(integrationSyncConfirmMessageA, { serviceName })}`,
      integrationSyncConfirmMessageB,
      transactionsLastSyncedAt
        ? Utils.replaceTextVars(
          uiTexts.lastSyncDate,
          { date: moment.duration(moment.utc(transactionsLastSyncedAt).diff(moment.utc())).humanize(true) }
        )
        : null
    ].filter(Boolean).join("\n\n");

    const result = await dispatch(UiActions.showModal(
      message,
      uiTexts.confirm,
      true,
      null,
      Utils.replaceTextVars(
        uiTexts.syncDataFromDate,
        { date: moment().subtract(1, "months").startOf("month").format(Constants.DATETIME_FORMATS.DATE_TEXT) }
      )
    ));

    if (result) {
      const usCountry = activeOrganization.countryCode === Countries.US;

      const xeroService = integrationService === IntegrationServices.XERO.value;

      const quickBooksService = integrationService === IntegrationServices.QUICK_BOOKS.value;

      const zohoService = integrationService === IntegrationServices.ZOHO.value;

      await dispatch(IntegrationsActions.syncAccounts(integrationService, businessIds, true, true));
      await dispatch(IntegrationsActions.syncClasses(integrationService, businessIds, true, true));
      if (xeroService || quickBooksService) {
        await dispatch(IntegrationsActions.syncItems(integrationService, businessIds, true, true));
      }
      if (xeroService || zohoService || (quickBooksService && !usCountry)) {
        await dispatch(IntegrationsActions.syncTaxRates(integrationService, businessIds, true, true));
      }
      if (quickBooksService) await dispatch(IntegrationsActions.syncLocations(integrationService, businessIds, true, true));
      await dispatch(IntegrationsActions.syncContacts(integrationService, businessIds, true, true));
      if (zohoService) await dispatch(IntegrationsActions.syncProjects(integrationService, businessIds, true, true));
      await dispatch(IntegrationsActions.syncTransactions(integrationService, businessIds, true, false));
      dispatch(BusinessesActions.fetchBusiness(businessId, true));
    }
  }, [
    uiTexts,
    activeOrganization,
    messages,
    businessId,
    integrationService,
    transactionsLastSyncedAt,
    dispatch
  ]);
};

export default useSyncIntegration;
